<template>
  <div>

    <template v-if="changed">
      <div class="alert alert-warning">Click <strong>Save</strong> to apply changes.</div>
    </template>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <template v-if="errors && errors.message">
      <template v-if="errors.message != 'Network Error'">
        <div class="alert alert-danger">
          {{ errors.message }}
        </div>
      </template>
    </template>
        
    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>{{ form.name }}</h1>
          <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="form.link && form.domain">

          <a :href="'https://'+form.link+'.'+form.domain" target="_blank" class="btn btn-outline-primary btn-sm text-uppercase">
            <template v-if="portal_status == 'offline'">
              <i class="fa fa-circle text-danger"></i>
            </template>
            <template v-else-if="portal_status == 'online'">
              <i class="fa fa-circle text-success"></i>
            </template>
            <template v-else-if="portal_status == 'loading'">
              <div class="spinner-grow spinner-grow-sm text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>

            Portal Status
          </a>

        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>
                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-right">
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="portal_reference">Portal Reference</label>
                <input
                  id="portal_reference"
                  v-model="form.portal_reference"
                  type="text"
                  class="form-control"
                  :disabled="!superAdmin"
                >
              </div>

              <div class="form-group">
                <label for="merge_tag">Merge Tag <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard('{'+form.merge_tag+'}')" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button></label>
                <input
                    id="merge_tag"
                    v-model="form.merge_tag"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="weather"
                    v-model="form.weather"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="weather"
                > Show Weather</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="default"
                  v-model="form.default"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="default"
                >Default</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
  
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title"><i class="fa fa-palette"></i> Theme and Brand</p>

            <div class="form-group">
              <label for="theme">Color Theme</label>
              <select id="theme" class="form-control" v-model="form.theme" @change="postForm()" :disabled="processing">
                <option value=""></option>
                <template v-for="(theme, theme_index) in themes">
                  <option :value="theme">{{ theme }}</option>
                </template>
              </select>
            </div>

            <label class="form-label">LOGO</label>
            <div class="input-group">
              <div class="custom-file">
                <input
                    id="logoUpload"
                    ref="logo"
                    type="file"
                    class="custom-file-input"
                    aria-describedby="logoUpload"
                    @change="storeLogo()"
                    :disabled="processing"
                >
                <label
                    class="custom-file-label"
                    for="logoUpload"
                >
                  Upload Logo
                </label>
              </div>
            </div>

            <template v-if="logoURL">

              <img
                  width="100"
                  class="mr-3"
                  :src="logoURL"
                  :alt="form.name + ' Logo'"
              >

              <div class="btn-group my-3">

                <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    @click="copyToClipboard(logoURL)"
                    :disabled="processing">
                  <i class="fa fa-copy"></i> Copy
                </button>

                <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="trashLogo()"
                    :disabled="processing"
                    :hidden="!form || !form.logo"
                >
                  <i class="fa fa-trash" /> Trash
                </button>
              </div>
            </template>

            <hr class="my-3">

            <label class="form-label">ICON</label>
            <div class="input-group">
              <div class="custom-file">
                <input
                    id="iconUpload"
                    ref="icon"
                    type="file"
                    class="custom-file-input"
                    aria-describedby="iconUpload"
                    @change="storeIcon"
                    :disabled="processing"
                >
                <label
                    class="custom-file-label"
                    for="iconUpload"
                >
                  Upload Icon
                </label>
              </div>
            </div>

            <template v-if="iconURL">

              <img
                  width="32"
                  class="mr-3"
                  :src="iconURL"
                  :alt="form.name + ' Icon'"
              >

              <div class="btn-group my-3">
                <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm"
                    @click="copyToClipboard(iconURL)"
                    :disabled="processing">
                  <i class="fa fa-copy"></i> Copy
                </button>

                <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="trashIcon()"
                    :disabled="processing"
                    :hidden="!form || !form.icon"
                >
                  <i class="fa fa-trash" /> Trash
                </button>
              </div>
            </template>

            <div class="form-group mt-3">
              <label for="custom_css">Custom CSS</label>
              <textarea
                  id="custom_css"
                  v-model="form.custom_css"
                  class="form-control"
                  @change="postForm()"
              />
            </div>
            <div class="form-group">
              <label for="custom_js">Custom JS</label>
              <textarea
                  id="custom_js"
                  v-model="form.custom_js"
                  class="form-control"
                  @change="postForm()"
              />
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-link"></i> Links and Redirects</p>

            <form @submit.prevent="postForm()">

            <div class="form-group">
              <label for="link">Portal Link</label>
              <input
                  id="link"
                  v-model="form.link"
                  class="form-control"
                  required
              >
            </div>

            <div class="form-group">
              <label for="domain">Domain</label>
              <select
                  id="domain"
                  v-model="form.domain"
                  class="form-control"
                  :disabled="processing"
                  required
              >
                <template v-for="(domain, domain_key) in domains">
                  <option :value="domain.apex">{{ domain.apex }}</option>
                </template>
              </select>
              <template v-if="form.alias">
                <p class="form-text text-muted small">Alias for {{ form.alias }}</p>
              </template>
            </div>

            <div class="form-group">
                <label for="redirect_link">Redirect Link</label>
                <input
                    id="redirect_link"
                    v-model="form.redirect"
                    type="text"
                    class="form-control"
                >
                <p class="form-text text-muted small">Auto-redirect to an external link.</p>
            </div>

              <div id="virtualLink" class="form-group">
                <label for="virtual_link">Virtual Link</label>
                <input
                    id="virtual_link"
                    v-model="form.virtual"
                    type="text"
                    class="form-control"
                >
                <p class="form-text text-muted small">Default virtual meeting link. Can be overridden on the event or location level.</p>
              </div>

              <template v-if="form.virtual">
                <div class="form-group">
                  <label for="virtual_code">Virtual Code</label>
                  <input
                      id="virtual_code"
                      v-model="form.virtual_code"
                      type="text"
                      class="form-control"
                  >
                </div>
              </template>

            <button type="submit" class="btn btn-success sr-only" :disabled="processing">Save</button>

            </form>

          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"><i class="fa fa-map-location-dot"></i> Tracking and Authentication</p>

            <div class="form-group mt-3">
              <label for="geolocation">Geolocation Tracking</label>
              <select
                  id="geolocation"
                  v-model="form.geolocation"
                  class="form-control"
                  @change="postForm()"
                  :disabled="processing"
              >
                <option value=""></option>
                <template v-for="(geolocation, geolocation_index) in geolocations">
                  <option :value="geolocation">{{ geolocation }}</option>
                </template>
              </select>
              <p class="form-text text-muted small">Can be overridden by Flow.</p>
            </div>

            <div class="form-group">
              <label for="portal_verify">Client Verification</label>
              <select
                  id="portal_verify"
                  v-model="form.verify"
                  class="form-control"
                  @change="postForm()"
                  :disabled="processing"
              >
                <option value=""></option>
                <template v-for="(verification, verification_index) in verifications">
                  <option :value="verification">{{ verification }}</option>
                </template>
              </select>
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
              <div class="card-body">

                <p class="card-title"><i class="fa fa-share"></i> Embed and Share</p>

                <p class="card-text">Use the embed code in an external website, kiosk, or mobile app.</p>


             <div class="form-group">
              <label for="embed_code">iFrame Embed Code</label>
              <textarea
                  id="embed_code"
                  :value="embedCode"
                  class="form-control"
                  rows="3"
                  @click="copyToClipboard(embedCode)"
                  readonly
              />
            </div>

            <div class="form-group">
              <label for="whitelist">Whitelist Domains</label>
              <textarea
                  id="whitelist"
                  v-model="form.settings.whitelist_domains"
                  class="form-control"
                  rows="3"
              />
              <p class="form-text text-muted small">Comma-separated list of domains to allow embedding.</p>
            </div>

              </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title"">Chatbot</p>
            <p class="card-text">Embed a chatbot with your portal.</p>

            <div class="form-group">
              <label for="chatbot_code">Chatbot Code</label>
              <textarea
                  id="chatbot_code"
                  class="form-control"
                  rows="3"
                  v-model="form.settings.chatbot"
              />
            </div>
            
        </div><!--Body-->
      </div><!--Card-->

        <audit-logs
          class="mt-3"
          :form="form"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">



            <p class="lead"><i class="fa fa-tablet"></i> Portal Preview</p>

              <p class="m-0 text-muted">Preview your portal in a browser. Click on the tabs to edit the content.</p>
              <p class="mb-1 text-muted">
                <i class="fa fa-language"></i> Switch languages to edit multilingual content.
              </p>

              <div class="row my-3">
              <div class="col-12">

            <div class="btn-group mb-3">
                  <a
                    :href="setMenuLink()"
                    target="_blank"
                    class="btn btn-outline-success btn-sm"
                  ><i class="fa fa-link" /> Open Link</a>

                  <a 
                  :href="setQRCodeLink()"
                  target="_blank"
                  class="btn btn-outline-success btn-sm"
                  >
                    <i class="fa fa-qrcode"></i> Generate QR Code
                  </a>

                  <template v-if="current_menu == 'enroll'">
                  <button type="button" class="btn btn-outline-success btn-sm" @click="downloadEnrollForm()" :disabled="processing">
                    <i class="fa fa-download"></i> Download Enroll Form
                  </button>
                  </template>

            </div><!--BtnGroup-->

         


            <div class="browser-container">
              <div class="browser-header">
                <div class="browser-buttons">
                  <div class="browser-button back"></div>
                  <div class="browser-button forward"></div>
                  <div class="browser-button refresh"></div>
                </div>
                <div class="browser-address-bar">
                  <span class="text-muted small"><i class="fa fa-lock text-success"></i> 
                    {{ setPortalLink() }}<span v-if="current_menu && current_menu != 'welcome'">/{{ current_menu }}</span>
                  </span>
                </div>
              </div>
              <div class="browser-content" style="overflow-x:auto">
                
                <div class="browser-tabs">
                  <template v-for="menu in menus">
                  <button
                      class="browser-tab text-uppercase"
                      :class="{'active': current_menu == menu}"
                      @click="changeMenu(menu)"
                  >
                   {{ menu }}
                  </button>
                </template>
                </div>

                <div class="container-fluid"
                :dir="currentLangDirection"
                >
              
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <template v-if="logoURL">
                    <img
                        :src="logoURL"
                        style="max-width:50px;max-height:50px;"
                        :alt="form.name + ' Logo'"
                    >
  
                    <br>
                  </template>
  
  
                  <label for="title" class="text-uppercase text-muted">Title
                    <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span>
                  </label>
                  <h1 class="mt-0 mb-3" contenteditable="true" @blur="editField('title', $event)" v-html="renderField('title')"></h1>
  
                </div><!--Col-->
                <div class="col-sm-12 col-md-6">

                  <div class="btn-group float-right">
                    <button
                        type="button"
                        class="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                    <template v-if="current_language">
                     <i class="fa fa-language"></i> {{ setLanguageLabel(current_language) }}
                    </template>
                    </button>
                    <div class="dropdown-menu">
                      <template v-for="language in languages">
                        <button
                            class="dropdown-item"
                            @click="changeLanguage(language.key)"
                            >
                          {{ language.label }}
                        </button>
                      </template>
                    </div>
                  </div>

                  </div><!--Col-->
              </div><!--Row-->
  
              <label for="alerts" class="text-uppercase text-muted">Alerts

                  <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span>

              </label>
              <div class="alert alert-danger" contenteditable="true" @blur="editField('alerts', $event)" v-html="renderField('alerts')"></div>
  
              <div class="row my-3">
                <div class="col-sm-12 col-md-4">
                  <div class="card">
                    <div class="card-body">
                        <label for="sidebar" class="text-uppercase text-muted">Sidebar Info 
                          <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span>
                        </label>

                        <p contenteditable="true" @blur="editField('sidebar', $event)">{{ renderField('sidebar') }}</p>

                        <div class="form-group custom-control custom-switch">
                          <input
                            id="show_contact_info"
                            v-model="form.settings.show_contact_info"
                            type="checkbox"
                            class="custom-control-input"
                          >
                          <label
                            class="custom-control-label"
                            for="show_contact_info"
                          >Show Agency Contact Info</label>
                        </div>

                        <form @submit.prevent="addSidebarNav()">

                          <p>Navigation</p>


                          <div class="form-group">
                            <label for="sidebar_nav_title">New Nav Title</label>
                            <input 
                              id="sidebar_nav_title"
                              v-model="sidebar_nav.title"
                              type="text"
                              class="form-control"
                              :disabled="processing"
                              required
                              >
                          </div>

                          <div class="form-group">
                            <label for="sidebar_nav_link">New Nav Link</label>
                            <input 
                              id="sidebar_nav_link"
                              v-model="sidebar_nav.link"
                              type="text"
                              class="form-control"
                              :disabled="processing"
                              required
                              >
                          </div>

                          <template v-if="isValidLink()">
                            <button type="submit" class="btn btn-success my-3" @click="addSidebarNav()" :disabled="processing">Add Navigation</button>
                          </template>

                          </form>


                        <p class="">Sidebar Navigation</p>

                        <template v-if="!form.settings || !form.settings.sidebar_nav">
                          <div class="alert alert-info">No sidebar navigation.</div>
                        </template>

                        <template v-if="showCurrentMenu()">
                          <button type="button" class="btn btn-success btn-sm my-3" @click="addCurrentMenuNav()" :disabled="processing">Add <span class="text-uppercase">{{ current_menu }}</span></button>   
                        </template>

                        <ul class="list-group mb-3">
                          <template v-if="form.settings && form.settings.sidebar_nav">
                          <template v-for="(sidebar_nav, sidebar_index) in form.settings.sidebar_nav">
                            <li 
                            v-if="sidebar_nav.link && sidebar_nav.title"
                            class="list-group-item"
                            >
                            <p contenteditable="true" @blur="editSidebarTitle(sidebar_index, $event)">{{ sidebar_nav.title }}</p>
                            <p contenteditable="true" @blur="editSidebarLink(sidebar_index, $event)">{{ sidebar_nav.link }}</p>

                            <template v-if="!sidebar_nav.status">
                              <button type="button" class="btn btn-success btn-sm" @click="toggleSidebarNav(sidebar_index, 'public')" :disabled="processing">Make Public</button>
                              <button type="button" class="btn btn-success btn-sm" @click="toggleSidebarNav(sidebar_index, 'private')" :disabled="processing">Make Private</button>

                            </template>

                            <template v-if="sidebar_nav.status == 'public'">
                              <button type="button" class="btn btn-warning btn-sm" @click="toggleSidebarNav(sidebar_index, 'private')" :disabled="processing">Make Private</button>
                            </template>

                            <template v-if="sidebar_nav.status == 'private'">
                              <button type="button" class="btn btn-danger btn-sm" @click="toggleSidebarNav(sidebar_index, 'public')" :disabled="processing">Make Public</button>
                            </template>

                              <div class="btn-group">
                                <button type="button" class="btn btn-secondary btn-sm" @click="moveSidebarNav(sidebar_index, 'up')" :disabled="processing" :hidden="sidebar_index == 0"><i class="fa fa-arrow-up"></i></button>
                                <button type="button" class="btn btn-secondary btn-sm" @click="moveSidebarNav(sidebar_index, 'down')" :disabled="processing" :hidden="sidebar_index == form.settings.sidebar_nav.length - 1"><i class="fa fa-arrow-down"></i></button>
                                <button type="button" class="btn btn-danger btn-sm" @click="removeSidebarNav(sidebar_index)" :disabled="processing"><i class="fa fa-trash"></i></button>
                              </div>
                            </li>
                          </template>
                          </template>
                        </ul>

                    </div><!--Body-->
                  </div><!--Card-->
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-8">
  
                  <template v-if="current_menu == 'events'">
  
                    <div class="card">
                      <div class="card-body">
                        <label for="events_title" class="text-uppercase text-muted">Body Info
                          <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span>
                        </label>
                        <p contenteditable="true"  @blur="editField('body', $event)">{{ renderField('body') }}</p>
                      </div><!--Body-->
                    </div><!--Card-->
  
                  </template>
  
                  <template v-if="current_menu == 'welcome'">
                    <div class="card">
                      <div class="card-body">

                        <label for="welcome" class="text-uppercase text-muted">Welcome Info <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span></label>
                          <editor 
                          id="welcome"
                          ref="welcome"
                          :options="$root.editorOptions"
                          initialEditType="wysiwyg"
                          :initialValue="setEditorValue('welcome')"
                          @change="changeMultiEditor('welcome')"
                          />

                          <template v-if="current_language != 'en'">
                            <button type="button" class="btn btn-success my-3" @click="autoTranslate(current_language, 'welcome')" :disabled="processing">Auto Translate</button>
                          </template>
                            
  
                      </div><!--Body-->
                    </div><!--Card-->
                  </template>
  
                  <template v-if="current_menu == 'dashboard'">

                    <template v-if="!form.verify">
                      <div class="alert alert-info">This feature requires the <code>Client Verification</code> setting to be enabled.</div>                    
                    </template>

                    <div class="card">
                      <div class="card-body">
                        <label for="dashboard" class="text-uppercase text-muted">Dashboard Info</label>
                          <editor 
                          id="dashboard"
                          ref="dashboard"
                          :options="$root.editorOptions"
                          initialEditType="wysiwyg"
                          @change="changeEditor('dashboard')"
                          />
  
                      </div>
                    </div>
                  </template>

                  <template v-if="current_menu == 'library'">
                    <div class="card">
                      <div class="card-body">
                        <label for="library" class="text-uppercase text-muted">Library Info</label>
                          <editor 
                          id="library"
                          ref="library"
                          :options="$root.editorOptions"
                          initialEditType="wysiwyg"
                          :initialValue="setEditorValue('library')"
                          @change="changeEditor('library')"
                          />
  
                      </div>
                    </div>
                  </template>

                  <template v-if="current_menu == 'checkin'">
                    
                    <div class="card">
                      <div class="card-body">

                        <h3>Check-In Settings</h3>

                        <template v-if="form.settings && form.settings['checkin_title']">

                        <div class="form-group">
                          <label for="checkin_title">Check-In Title</label>
                          <input 
                            id="checkin_title"
                            v-model="form['settings']['checkin_title'][current_language]"
                            type="text"
                            class="form-control"
                            :disabled="processing"
                            @keyup.enter="postForm()"
                            >
                        </div>

                        <div class="form-group">
                          <label for="checkin_description">Check-In Description</label>
                          <input 
                            id="checkin_description"
                            v-model="form['settings']['checkin_description'][current_language]"
                            type="text"
                            class="form-control"
                            :disabled="processing"
                            @keyup.enter="postForm()"
                            >
                        </div>

                        </template>

                        
                        <div class="form-group">
                          <label for="checkin_location">Check-In Location</label>
                          <select id="checkin_location" class="form-control" v-model="form.settings['checkin_location']"
                          @change="postForm()" 
                          :disabled="processing"
                          >
                            <option value=""></option>
                            <template v-for="location in locations">
                              <option :value="location.uuid">{{ location.name }} 
                              
                                <template v-if="location.virtual">
                                  (VIRTUAL)
                                </template>
                              </option>
                            </template>
                          </select>
                          <template v-if="form.settings['checkin_location']">
                            <p class="form-text text-muted small mb-0"><a :href="'/locations/'+form.settings['checkin_location']" target="_blank">Edit Location</a></p>
                          </template>
                        </div>

                        <template v-if="!processing && locations && locations.length == 0">
                          <div class="alert alert-info">No locations available. <a href="/locations" target="_blank">Create a location</a></div>
                        </template>

                        <div class="form-group">
                          <label for="checkin_notify">Notify on Check-In</label>
                          <input 
                            id="checkin_notify"
                            v-model="form['settings']['checkin_notify']"
                            type="text"
                            class="form-control"
                            :disabled="processing"
                            @keyup.enter="postForm()"
                            >
                            <small class="form-text text-muted">Comma-separated list of phones and emails to notify on checkins.</small>
                        </div>

                        <div class="form-group custom-control custom-switch">
                              <input
                                  id="checkin_notify_user"
                                  v-model="form.settings.checkin_notify_user"
                                  type="checkbox"
                                  class="custom-control-input"
                              >
                              <label
                                  class="custom-control-label"
                                  for="checkin_notify_user"
                                  > Notify Assigned User on Check In</label>
                        </div>


                        <div class="form-group">
                          <label for="checkin_auto_message">Auto Message on Check-In</label>
                          <select id="checkin_auto_message" class="form-control" v-model="form.settings['checkin_auto_message']">
                            <option value=""></option>
                            <template v-for="auto_message in auto_messages">
                              <option :value="auto_message.uuid">{{ auto_message.name }}</option>
                            </template>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="checkin_max_distance">Maximum Distance</label>
                          <input 
                            id="checkin_max_distance"
                            v-model="form['settings']['checkin_max_distance']"
                            type="number"
                            class="form-control"
                            :disabled="processing"
                            @keyup.enter="postForm()"
                            >
                            <small class="form-text text-muted">Max miles from location to allow checkins.</small>
                        </div>

                        <!--Checkin Fields-->
                        <div class="form-group">
                          <label for="checkin_fields">Check-in Fields</label>
                          <multi-select
                              id="checkin_fields"
                              v-model="form['settings']['checkin_fields']"
                              :options="checkin_fields"
                              label="name"
                              track-by="key"
                              :multiple="true"
                              :close-on-select="false"
                              @change="postForm()"
                          />
                        </div>

                        <button type="button" class="btn btn-success my-3" @click="setDefaultCheckinFields()" :disabled="processing">Configure Default Fields</button>

                        <template v-for="(field, field_index) in form['settings']['checkin_fields']">
                            <div class="form-group">
                              <label :for="'checkin_field_'+field.name" 
                                contenteditable="true" @blur="editCheckinField(field_index, $event)"
                              >{{ field.name }}
                            </label>
                            <template v-if="field.required == true">
                                *
                            </template>
                              <input
                                  :id="'checkin_field_'+field.name"
                                  type="text"
                                  class="form-control"
                                  :value="field.default"
                                  :readonly="field.hidden"
                                  :required="field.required"
                              >


                            </div>
                            </template>
  
                      </div><!--Body-->
                    </div><!--Card-->
                  </template>
  
                  <template v-if="current_menu == 'calendars'">
                  
                        <label for="calendar_title" class="text-uppercase text-muted">Calendar Title
                          <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span>
                        </label>
                        <p contenteditable="true" @blur="editField('calendar_title', $event)">{{ renderField('calendar_title') }}</p>
  
                        <label for="calendar_description" class="text-uppercase text-muted">Calendar Description
                          <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span>
                        </label>
                        <p contenteditable="true" @blur="editField('calendar_description', $event)">{{ renderField('calendar_description') }}</p>
                    
                  </template>
  
                  <template v-if="current_menu == 'optouts'">
                    
                    <label for="optouts_title" class="text-uppercase text-muted">Opt-Outs Title</label>
                    <p v-html="form.optouts['title'][current_language] || form.optouts['title']['en'] || ''" contenteditable="true" @blur="editField('optouts.title', $event)"></p>
  
                    <div class="card">
                      <div class="card-body">
                        <label for="optouts_reasons" class="text-uppercase text-muted">Opt-Out Reasons</label>
                        <p v-html="form.optouts['reasons'][current_language] || form.optouts['reasons']['en'] || ''" contenteditable="true" @blur="editField('optouts.reasons', $event)"></p>
                      </div>
                    </div>
  
                  </template>
  
                  <template v-if="current_menu == 'enroll'">
  
                  
                    <label for="enroll_title" class="text-uppercase text-muted">Enroll Title</label>
                    <h1 contenteditable="true" @blur="editEnrollSetting('enroll_title', $event)">{{ form.enroll_title || '' }}</h1>
  
                    <div class="card">
                      <div class="card-body">
                        <label for="enroll_description" class="text-uppercase text-muted">Enroll Description</label>
                        <p contenteditable="true" @blur="editEnrollSetting('enroll_description', $event)">{{ form.enroll_description || '' }}</p>
  
                        <div class="form-group">
                          <label for="enroll_fields" class="text-uppercase text-muted">Enroll Fields</label>
                          <multi-select
                              id="enroll_fields"
                              v-model="form.enroll_enabled_fields"
                              :options="portal_fields"
                              label="name"
                              track-by="key"
                              :multiple="true"
                              :close-on-select="false"
                          />
                        </div>
    
                        <draggable
                        ref="enroll_fields"
                        v-model="form.enroll_enabled_fields"
                        group="enroll_fields"
                        @start="drag=true"
                        @end="drag=true"
                        >

                        <button type="button" class="btn btn-success my-3" @click="setDefaultEnrollFields()" :disabled="processing">Configure Default Fields</button>

                          <template v-for="(field, field_index) in form.enroll_enabled_fields">
                        <div class="my-3">
                            <div class="form-group">
                              <label :for="'enroll_field_'+field_index" 
                              contenteditable="true" 
                              @blur="editEnrollField(field_index, $event)"
                              >{{ field.name }}
                            </label>
                            <template v-if="field.required == true">
                                *
                            </template>
                              <input
                                  :id="'enroll_field_'+field_index"
                                  type="text"
                                  class="form-control"
                                  v-model="form.enroll_enabled_fields[field_index].default"
                                  :readonly="field.hidden"
                                  :required="field.required"
                              >
                            </div>
  
                            <div class="form-group custom-control custom-switch">
                              <input
                                  :id="'required_field_'+field.key"
                                  v-model="field.required"
                                  type="checkbox"
                                  class="custom-control-input"
                              >
                              <label
                                  class="custom-control-label"
                                  :for="'required_field_'+field.key"
                              > Required</label>
                            </div>
  
                            <div class="form-group custom-control custom-switch">
                              <input
                                  :id="'hidden_field_'+field.key"
                                  v-model="field.hidden"
                                  type="checkbox"
                                  class="custom-control-input"
                              >
                              <label
                                  class="custom-control-label"
                                  :for="'hidden_field_'+field.key"
                              > Hidden</label>
                            </div>

                            <hr>
                            
                            </div><!--Row-->
                          </template>
                        </draggable>
  
                        <template v-if="form.enroll_enabled_fields && form.enroll_enabled_fields.length > 0">

                          <div class="form-group custom-control custom-switch">
                              <input
                                  id="auto_optin"
                                  v-model="form.settings.auto_optin"
                                  type="checkbox"
                                  class="custom-control-input"
                              >
                              <label
                                  class="custom-control-label"
                                  for="auto_optin"
                                  > Auto Optin</label>
                              <p class="form-text text-muted small">Automatically opt-in clients on enrollment.</p>
                           </div>

  
                          <button type="button" class="btn btn-success my-3" @click="simulateClients(1)" :disabled="processing">Enroll</button>
  
  
                          <div class="form-group my-3">
                            <label for="enroll_contacts">Enroll Notification Contacts</label>
                            <input
                                id="enroll_contacts"
                                v-model="form.enroll_contacts"
                                type="text"
                                class="form-control"
                                @change="postForm()"
                            >
                            <p class="small text-muted">
                              Comma-separated list of phones and emails to notify on enrollments.
                            </p>
                          </div>

                          <div class="form-group">
                            <label for="enroll_submit_title">Submit Button Title</label>
                            <input 
                              id="enroll_submit_title"
                              v-model="form.settings.enroll_submit_title"
                              type="text"
                              class="form-control"
                              :disabled="processing"
                              @keyup.enter="postForm()"
                              >
                          </div>

                          <div class="form-group">
                            <label for="enroll_confirmation">Confirmation Info after Enroll</label>
                            <input 
                              id="enroll_confirmation"
                              v-model="form.settings.enroll_confirmation"
                              type="text"
                              class="form-control"
                              :disabled="processing"
                              @keyup.enter="postForm()"
                              >
                          </div>

                          <div class="form-group">
                            <label for="enroll_redirect">Redirect after Enroll</label>
                            <input 
                              id="enroll_redirect"
                              v-model="form.settings.enroll_redirect"
                              type="text"
                              class="form-control"
                              :disabled="processing"
                              @keyup.enter="postForm()"
                              >
                          </div>
  
                        </template>
                      
                          </div><!--Body-->
  
                    </div><!--Card-->
  
                  </template><!--Enroll-->

                  <template v-if="current_menu == 'profile'">

                    <h4>Client Profile</h4>

                    <p class="text-muted">Allow authenticated clients to view and manage their own profile.</p>

                    <template v-if="!form.verify || form.verify != 'one-time-password'">
                      <div class="alert alert-info">This feature requires the <code>Client Verification</code> setting to be enabled.</div>
                    </template>

                    <div class="form-group">
                      <label for="profile_client">Client Profile</label>
                      <select id="profile_client" class="form-control" v-model="form.settings.profile_client">
                        <option value="">Read-only (default)</option>
                        <option value="crud">Read and Update</option>
                      </select>
                      <p class="form-text text-muted small">Allow clients to view manage their own basic profile</p>
                    </div>

                    <div class="form-group">
                      <label for="profile_contacts">Contacts</label>
                      <select id="profile_contacts" class="form-control" v-model="form.settings.profile_contacts">
                        <option value="">Read-only (default)</option>
                        <option value="crud">Create, Read, Update</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="profile_addresses">Addresses</label>
                      <select id="profile_addresses" class="form-control" v-model="form.settings.profile_addresses">
                        <option value="">Read-only (default)</option>
                        <option value="crud">Create, Read, Update</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="profile_documents">Documents</label>
                      <select id="profile_documents" class="form-control" v-model="form.settings.profile_documents">
                        <option value="">Read-only (default)</option>
                        <option value="crud">Create, Read, Update</option>
                      </select>
                    </div>
                  
                    
                  </template>

                  <template v-if="current_menu == 'search'">

                    <h4>Case Search</h4>

                    <p class="text-muted">Allow clients and public users to lookup case and event information.</p>

                    <div class="form-group">
                      <label for="allow_case_search">Allow Case Search</label>
                      <select
                          id="allow_case_search"
                          v-model="form.settings.allow_case_search"
                          class="form-control"
                          @change="postForm()"
                      >
                        <option value="">Disabled (default)</option>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                      </select>
                    </div>
                    
                    <div class="form-group">
                      <label for="search_case_types">Enabled Case Types</label>
                      <multi-select
                          id="search_case_types"
                          v-model="form.settings.search_case_types"
                          :options="case_types"
                          :multiple="true"
                          :close-on-select="false"
                          @change="postForm()"
                      />
                    </div>

                    <div class="form-group">
                      <label for="search_case_statuses">Enabled Case Statuses</label>
                      <multi-select
                          id="search_case_statuses"
                          v-model="form.settings.search_case_statuses"
                          :options="case_statuses"
                          label="name"
                          track-by="key"
                          :multiple="true"
                          :close-on-select="false"
                          @change="postForm()"
                      />
                    </div>

                    <div class="form-group">
                      <label for="search_operator">Case Number Operator</label>
                      <select
                          id="search_operator"
                          v-model="form.settings.search_operator"
                          class="form-control"
                          @change="postForm()"
                      >
                        <option value="partial">Partial Match (default)</option>
                        <option value="exact">Exact Match</option>
                      </select>
                    </div>
                  
                  </template>

                  <template v-if="current_menu == 'posts'">

                    <h4>Posts</h4>

                    <p class="text-muted">Share updates, instructions, and news with clients and public users.</p>

                    <div class="form-group">
                      <label for="posts_title">Posts Title</label>
                      <input 
                        id="posts_title"
                        v-model="form.settings.posts_title"
                        type="text"
                        class="form-control"
                        :disabled="processing"
                        @keyup.enter="postForm()"
                        >
                    </div>

                    <div class="form-group">
                      <label for="posts_description">Posts Description</label>
                      <input 
                        id="posts_description"
                        v-model="form.settings.posts_description"
                        type="text"
                        class="form-control"
                        :disabled="processing"
                        @keyup.enter="postForm()"
                        >
                    </div>

                    <div class="form-group">
                      <label for="posts_category">Posts Category</label>
                      <input 
                        id="posts_category"
                        v-model="form.settings.posts_category"
                        type="text"
                        class="form-control"
                        :disabled="processing"
                        @keyup.enter="postForm()"
                        >
                    </div>


                  </template>

                  <template v-if="current_menu == 'attorneys'">

                    <h4>Attorneys</h4>

                    <p class="text-muted">Allow clients and public users to lookup attorney information.</p>

                        <div class="form-group custom-control custom-switch">
                          <input
                            id="public_attorneys"
                            v-model="form.settings.public_attorneys"
                            type="checkbox"
                            class="custom-control-input"
                          >
                          <label
                            class="custom-control-label"
                            for="public_attorneys"
                          >Enable Public Attorneys</label>
                        </div>

                    </template>
                    
                </div><!--Col-->
  
              </div><!--Row-->
  
              <label for="footer m-0" class="text-uppercase text-muted">Footer
                <i class="fa fa-language text-muted"></i> <span class="sr-only">Multilingual</span>
              </label>
              <p class="m-0" contenteditable="true" @blur="editField('footer', $event)" v-html="renderField('footer')"></p>
  
              </div><!--Container-->


              </div>
            </div>

        </div><!--Col-->

        </div><!--Row-->


      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';
import draggable from 'vuedraggable';
import MergeTag from "@/components/MergeTag.vue";

export default {

  components: {MergeTag, draggable },

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      changed: false,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        fields: 'name,uuid,link,portal_reference,redirect,created_at',
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {
        name: null,
        link: null
      },
      sidebar_nav: {
        title: null,
        link: null
      },
      columns: ['name', 'link', 'reference', 'redirect', 'created_at'],
      portal_fields: [],
      checkin_fields: [],
      languages: [],
      current_language: 'en',
      current_menu: 'welcome',
      qr_code: null,
      total_messages: 0,
      messages: [],
      message_queries: {
          slim: true,
          limit: 100,
          direction: 'outbound',
          sort: 'scheduled_at',
          sortDirection: 'desc',
          fields: 'uuid,content,last_status,test,last_open,client,to,from,scheduled_at,type,direction'
      },
      domains: [],
      locations: [],
      auto_messages: [],
      errors: null,
      geolocations: ['optional', 'required', 'continuous'],
      verifications: ['one-time-password'],
      portal_status: 'loading',
      themes: ['auto', 'light', 'dark'],
      menus: ['welcome', 'enroll', 'dashboard', 'library', 'checkin', 'calendars', 'maps', 'optouts', 'events', 'schedule', 'profile', 'search', 'posts', 'judges', 'attorneys'],
      case_types: [],
      case_statuses: []
    }
  },

  computed: {

        superAdmin () {
          return this?.$root?.current_user?.super_admin;
        },


        logoURL() {

          if(this.form && this.form.logo) {
            return this.$root.portal_assets+this.form.logo;
          }


          if(this.settings && this.settings.logo) {
            return this.settings.logo;
          }
        },

        iconURL() {

          if(this.form && this.form.icon) {
            return this.$root.portal_assets+this.form.icon;
          }

          if(this.settings && this.settings.icon) {
            return this.settings.icon;
          }
        },

        embedCode() {
          if(!this.form || !this.form.link) {
            return;
          }

          return '<iframe src="https://'+this.form.link+'.'+this.form.domain+'" title="'+this.form.name+'" width="100%" height="100%" frameborder="0" border="0" loading="eager" allowfullscreen="true"></iframe>';
        },

        settings() {
          var settings = localStorage.getItem('settings');

          if(settings) {
            settings = JSON.parse(settings);
          }

          if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
            settings = this.$root.current_user.current_agency.settings;
          }

          return settings;
        },


        currentLangDirection() {

          if(!this.current_language) {
            return 'ltr';
          }

          if(this.$root.rtl_langs.includes(this.current_language)) {
            return 'rtl';
          }

          return 'ltr';

        },

  },

  mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
    });

  },

  created() {    

    this.resetForm();
    this.setURLParams();
    this.getDomains();
    this.getRecord();
    this.getPortalFields();
    this.getLanguages();
    this.getLocations();
    this.getAutoMessages();
    this.getCaseStatuses();
  },

  methods: {

    setTitle() {
        document.title = this.form.name + ' | eCourtDate.com';
    },

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    postForm() {
      this.processing = true;
      
      this.errors = null;

      this.changed = false;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }

        }
      })
    },

    resetForm() {

        this.qr_code = null;

        this.form = {
            name: '',
            link: '',
            domain: '',
            redirect: null,
            title: {
              'en': ''
            },
            welcome: {
              'en': ''
            },
            home: {
              'en': ''
            },
            sidebar: {
                'en': ''
            },
            body: {
                'en': ''
            },
            footer: {
                'en': ''
            },
            alerts: {
                'en': ''
            },
            calendar_title: {
                'en': ''
            },
            calendar_description: {
              'en': ''
            },
            optouts: {
              reasons: {
                'en': ''
              },
              title: {
                'en': ''
              }
            },
            settings: {},
            logo: '',
            icon: '',
            geolocation: '',
            calendar: null,
            calendar_url: null,
            default: false,
            weather: true,
            virtual: null,
            virtual_code: null,
            verify: '',
            services: '',
            merchant_id: '',
            self_resources: [],
            enroll_enabled_fields: []
        }
    },

   getRecord() {
    this.processing = true;
    this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
    .then(response => {
      if(response && response.data) {

        if(response.data.uuid) {
          this.form = response.data;
          this.setTitle();
          this.setFields();

          this.getPortalStatus();
        }

        this.processing = false;
      }
    })
   },

   getLocations() {
    this.locations = [];
    this.$http.get('/locations?slim=true&limit=100&fields=name,uuid,virtual&sort=name&sortDirection=asc')
    .then(response => {
      if(response && response.data) {
        this.locations = response.data;
      }
    })
   },

   getAutoMessages() {
    this.auto_messages = [];
    this.$http.get('/auto_messages?slim=true&limit=100&fields=name,uuid&sort=name&sortDirection=asc')
    .then(response => {
      if(response && response.data) {
        this.auto_messages = response.data;
      }
    })
   },

    getPortalStatus() {

      if(!this.form.link || !this.form.domain) {
        return;
      }

      this.portal_status = 'loading';

      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/status')
          .then(response => {
            if(response && response.status) {
              this.portal_status = response.data;
            }
          })
    },

    setMultiFields() {
      return ['title', 'alerts', 'sidebar', 'welcome', 'home', 'footer', 'body', 'calendar_title', 'calendar_description'];
    },

   setFields() {

    var fields = this.setMultiFields();

    fields.forEach(f => {
        if(!this.form[f]) {
            this.form[f] = {'en': ''};
        }
    });

    if(!this.form['optouts'] || !this.form['optouts']['reasons']) {
      this.form['optouts'] = {
        reasons: {
          'en': ''
        }
      }
    }

    if(!this.form['optouts']['title']) {
      this.form['optouts']['title'] = {
        'en': ''
      }
    }

    if(!this.form['settings']) {
      this.form['settings'] = {};
    }

    if(!this.form['settings']['auto_optin']) {
      this.form['settings']['auto_optin'] = false;
    }

    if(!this.form['settings']['dashboard']) {
      this.form['settings']['dashboard'] = {};
    }

    if(!this.form['settings']['library']) {
      this.form['settings']['library'] = {};
    }

    if(!this.form['settings']['sidebar_nav']) {
      this.form['settings']['sidebar_nav'] = [];
    }

    var fields = ['checkin_location', 'checkin_max_distance', 'checkin_notify'];

    fields.forEach(f => {
      if(!this.form['settings'][f]) {
        this.form['settings'][f] = '';
      }
    });

    var settings = ['checkin_title', 'checkin_description'];

    settings.forEach(s => {
      if(!this.form['settings'][s]) {
        this.form['settings'][s] = {};
      }
    });

    this.resetEditors();
    
   },

        getLanguages () {
            this.$http.get('languages')
            .then(response => {
                if(response && response.data && response.data.length) {
                    this.languages = response.data;
                }
            })
        },

        trashLogo() {
            this.form.logo = '';
            this.changed = true;
        },

        trashIcon() {
            this.form.icon = '';
            this.changed = true;
        },

        setCheckinFields() {
          this.checkin_fields = [
            {
              type: 'text',
              key: 'name',
              name: 'Name',
              required: true,
              hidden: false,
              default: '',
            },
            {
              type: 'text',
              key: 'first_name',
              name: 'First Name',
              required: true,
              hidden: false,
              default: '',
            },
            {
              type: 'text',
              key: 'last_name',
              name: 'Last Name',
              required: true,
              hidden: false,
              default: '',
            },
            {
              type: 'email',
              key: 'email',
              name: 'Email',
              required: true,
              hidden: false,
              default: '',
            },
            {
              key: 'phone',
              type: 'tel',
              name: 'Phone',
              required: true,
              hidden: false,
              default: '',
            },
            {
              key: 'department',
              type: 'text',
              name: 'Department',
              required: false,
              hidden: false,
              default: '',
            },
            {
              key: 'service',
              type: 'text',
              name: 'Service',
              required: false,
              hidden: false,
            },
            {
              key: 'notes',
              type: 'longtext',
              name: 'Notes',
              required: false,
              hidden: false,
            },
            {
              key: 'reference',
              type: 'text',
              name: 'Reference',
              required: false,
              hidden: false,
            },
            {
              key: 'case_number',
              type: 'text',
              name: 'Case Number',
              required: false,
              hidden: false,
            },
            {
              key: 'judge',
              type: 'text',
              name: 'Judge',
              required: false,
              hidden: false,
            },
            {
              key: 'officer',
              type: 'text',
              name: 'Officer',
              required: false,
              hidden: false,
            },
            {
              key: 'case_type',
              type: 'text',
              name: 'Case Type',
              required: false,
              hidden: false,
            },
            {
              key: 'case_status',
              type: 'text',
              name: 'Case Status',
              required: false,
              hidden: false,
            }
          ]
        },

      getPortalFields () {

        this.portal_fields = [];

        this.setCheckinFields();

          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/fields.json')
          .then(response => {
              if(response && response.data) {

                if(response.data.length) {
                  this.setPortalFields(response.data);
                }

              }
          })
      },


      setPortalFields(fields)
      {
        fields.forEach(field => {
          delete field['samples'];

          delete field['unique'];

          delete field['pii'];

          delete field['upload'];

          delete field['split'];

          if(!field['name'] && field['key']) {
            field['name'] = field['key'];
          }

          field['default'] = '';

          field['required'] = false;

          field['hidden'] = false;

        });

        this.portal_fields = fields;

      },

        storeLogo () {
            if (this?.$refs?.logo?.files?.length > 0) {
                this.processing = true
                this.storeUpload(this.$refs.logo.files[0])
                .then(response => {
                    if (response && response.uuid) {
                        this.form.logo = response.uuid;
                        this.processing = false;
                    }
                })
            }
        },

        storeIcon () {
            if (this?.$refs?.icon?.files?.length > 0) {
                this.processing = true
                this.storeUpload(this.$refs.icon.files[0])
                .then(response => {
                    if (response && response.uuid) {
                        this.form.icon = response.uuid;
                        this.processing = false;
                    }
                })
            }
        },

        async storeUpload (file) {
            const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
                'bucket': 'ecdportals',
                'content_type': file.type,
                'content_length': file.size,
                'expires': '',
                'visibility': ''
            })
            if (response && response.data) {
              
                let headers = response.data.headers;

                if ('Host' in headers) {
                    delete headers.Host
                }
                await this.$axios.put(response.data.url, file, {
                    headers: headers
                });

                response.data.extension = file.name.split('.').pop();

                return response.data;
            }
            return null;
        },
   

      getQRCode() {

        if(!this.form.link) {
          return;
        }

        this.$http.post('/qrs?content='+this.setPortalLink())
        .then(response => {
            if(response && response.data) {
                this.qr_code = response.data;
            }
        })
      },

      autoTranslate(language, key) {

        this.processing = true;

        var translator = {
          source_language: 'en',
          target_language: language,
          content: this.form[key]['en']
        }

        this.$http.post('/translator', translator)
        .then(response => {
          if(response && response.data) {

            if(response.data.content) {
              this.form[key][language] = response.data.content;
              new this.$noty({text: 'Added translation to '+ language}).show();
              this.postForm();
            }

            this.processing = false;
          }
        })
      },

      restoreRecord () {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },

      archiveRecord () {
          this.processing = true
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                      this.form.archived_at = new Date().toISOString();
                  }

              }
              
          })
      },

      trashRecord () {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
      },

    getDomains() {
      this.$http.get('/domains?fields=domain&limit=100&sortDirection=asc&sort=domain')
      .then(response => {
        if(response && response.status) {
          this.domains = response.data;
          
          this.domains.unshift({'apex': 'ecase.io'});
        }
      })
    },

    setMenuLink() {

      if(!this.current_menu) {
        return this.setPortalLink();
      }

      if(this.current_menu == 'welcome') {
        return this.setPortalLink();
      }

      return this.setPortalLink()+'/'+this.current_menu;
    },

    setQRCodeLink() {

      let queries = {
        description: this.form.enroll_description,
        title: this.form.enroll_title,
        portal: this.form.uuid,
        content: this.setMenuLink(),
        logo: this.logoURL,
        language: this.current_language
      }

      if(this.current_menu == 'checkin') {
        queries['title'] = this.form.settings.checkin_title[this.current_language] || this.form.settings.checkin_title['en'];
        queries['description'] = this.form.settings.checkin_description[this.current_language] || this.form.settings.checkin_description['en'];
      }

      if(this.current_menu == 'enroll') {
        queries['title'] = this.form.enroll_title;
        queries['description'] = this.form.enroll_description;
      }

      return this.buildQueries('/qrs', queries);
    },

    setPortalLink() {

      if(!this.form.link) {
        return;
      }

      var domain = this.form.domain || 'ecase.io';

      return 'https://'+this.form.link+'.'+domain;
    },

    isValidLink() {
      if(!this.sidebar_nav.title || !this.sidebar_nav.link) {
        return false;
      }

      if(!this.sidebar_nav.link.indexOf('http') == 0) {
        return false;
      }

      return true;
    },

    editSidebarTitle(index, event) {
      this.form.settings.sidebar_nav[index].title = event.target.innerText;
      this.changed = true;
    },

    editSidebarLink(index, event) {
      this.form.settings.sidebar_nav[index].link = event.target.innerText;
      this.changed = true;
    },

    toggleSidebarNav(index, status) {
      this.form.settings.sidebar_nav[index].status = status;
      this.changed = true;
      this.postForm();
    },

    moveSidebarNav(index, direction) {
      var nav = this.form.settings.sidebar_nav[index];

      if(direction == 'up') {
        this.form.settings.sidebar_nav.splice(index, 1);
        this.form.settings.sidebar_nav.splice(index-1, 0, nav);
      }

      if(direction == 'down') {
        this.form.settings.sidebar_nav.splice(index, 1);
        this.form.settings.sidebar_nav.splice(index+1, 0, nav);
      }

      this.changed = true;
    },

    removeSidebarNav(index) {
      this.form.settings.sidebar_nav.splice(index, 1);
      this.changed = true;
    },

    showCurrentMenu() {
      if(!this.form.settings.sidebar_nav) {
        return true;
      }

      var nav = this.form.settings.sidebar_nav.find(nav => nav.link == this.setPortalLink()+'/'+this.current_menu);

      if(nav) {
        return false;
      }

      return true;
    },

    addCurrentMenuNav() {
      if(!this.form.settings.sidebar_nav) {
          this.form.settings.sidebar_nav = [];
        }
  
        this.form.settings.sidebar_nav.unshift(
          {
            title: this.current_menu.toUpperCase(),
            link: this.setPortalLink()+'/'+this.current_menu
          }
        );
    },

    addSidebarNav() {

      if(!this.isValidLink()) {
        new this.$noty({text: 'Invalid link', type: 'error'}).show();
        return;
      }
      
        if(!this.form.settings.sidebar_nav) {
          this.form.settings.sidebar_nav = [];
        }
  
        this.form.settings.sidebar_nav.push(this.sidebar_nav);
  
        this.changed = true;

        this.sidebar_nav = {
          title: null,
          link: null
        }
  
        this.postForm();
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
    },

    setDefaultEnrollFields() {

      this.form.enroll_enabled_fields = [
        {name: "First Name", key: "clients.first_name", type: "Short Text", required: true, hidden: false, default: null},
        {name: "Last Name", key: "clients.last_name", type: "Short Text", required: true, hidden: false, default: null},
        {name: "Email", key: "email", type: "Email", required: false, hidden: false, default: null},
        {name: "Phone", key: "phone", type: "Telephone", required: true, hidden: false, default: null},
      ];

      this.form.enroll_contacts = this.$root.current_user.email;
      this.form.enroll_title = 'Sign Up to Receive Case Notifications';
      this.form.enroll_description = 'Please fill out the form below to receive notifications.';

      this.changed = true;

    },

    setDefaultCheckinFields() {

      if(!this.form.settings['checkin_title'] || !this.form.settings['checkin_title']['en']) {
        this.form.settings['checkin_title'] = {
        'en': 'Check In'
        }
      }

      if(!this.form.settings['checkin_description'] || !this.form.settings['checkin_description']['en']) {
        this.form.settings['checkin_description'] = {
        'en': 'Please fill out the form below to check in.'
        }
      }

      this.form.settings['checkin_fields'] = [
            {
              type: 'text',
              key: 'name',
              name: 'Name',
              required: true,
              hidden: false,
              default: '',
            },
            {
              type: 'email',
              key: 'email',
              name: 'Email',
              required: true,
              hidden: false,
              default: '',
            },
            {
              key: 'phone',
              type: 'tel',
              name: 'Phone',
              required: true,
              hidden: false,
              default: '',
            },
            {
              key: 'notes',
              type: 'longtext',
              name: 'Notes',
              required: false,
              hidden: false,
            }
          ]

      this.changed = true;

      },

    downloadEnrollForm() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/enroll_form')
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(!response.data.pdf) {
            return;
          }

          var binary = atob(response.data.pdf);

          var array = [];

          for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }

          var blob = new Blob([new Uint8Array(array)], {type: 'application/pdf'});

          var link = document.createElement('a');

          link.href = window.URL.createObjectURL(blob);

          link.download = this.form.name + ' Enroll Form.pdf';

          link.click();


        }
      })
    },

    simulateClients(count) {

      if(!count) {
        return;
      }

      var groups = this.settings['default_groups'] || 'Test';

      var groups = groups.split(',');

      var group = null;

      if(!groups) {
        group = 'Test';
      } else {
        group = groups[this.getRandomIndex(groups.length)];
      }

      group = group.trim();

      for(var i = 0; i < count; i++) {
        this.simulateEnroll(group);
      }
    },

    simulateEnroll(group) {
      this.processing = true;

      var name = this.simulateName();

      var post = {
        'first_name': name.first_name,
        'last_name': name.last_name,
        'middle_name': this.$faker.person.middleName(),
        'language': name.language,
        'phone': this.$faker.phone.number(),
        'email': this.$faker.internet.email(),
        'dob': this.$faker.date.birthdate(),
        'group': group,
        'test': true
      }

      this.$http.post('/clients', post)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          new this.$noty({text: 'Simulated enrollment'}).show();
        }
      })
    },

    simulateName() {

      const names = [
        {
          firsts: ["James", "Emma", "Oliver", "Olivia", "Noah", "Ava"],
          lasts: ["Smith", "Johnson", "Brown", "Jones", "Garcia", "Miller"],
          language: "en"
        },
        {
          firsts: ["Liam", "Sophia", "Benjamin", "Isabella", "Mason", "Mia"],
          lasts: ["Silva", "Santos", "Oliveira", "Souza", "Rodrigues", "Ferreira"],
          language: "pt"
        },
        {
          firsts: ["Lucas", "Sophia", "Liam", "Isabella", "Noah", "Emma"],
          lasts: ["Dubois", "Martin", "Bernard", "Thomas", "Petit", "Robert"],
          language: "fr"
        },
        {
          firsts: ["Hugo", "Sofia", "Martin", "Maria", "Lucas", "Laura"],
          lasts: ["Mueller", "Schmidt", "Schneider", "Fischer", "Weber", "Meyer"],
          language: "de"
        },
        {
          firsts: ["Lukas", "Emma", "Adam", "Julia", "Jakub", "Zuzanna"],
          lasts: ["Nowak", "Kowalski", "Wiśniewski", "Wójcik", "Kowalczyk", "Kamiński"],
          language: "pl"
        },
        {
          firsts: ["Sofia", "Diego", "Isabella", "Santiago", "Valentina", "Mateo"],
          lasts: ["Garcia", "Martinez", "Rodriguez", "Hernandez", "Lopez", "Gonzalez"],
          language: "es"
        },
        {
          firsts: ["Li", "Yuki", "Min", "Jin", "Hao", "Chen"],
          lasts: ["Kim", "Wang", "Nguyen", "Tran", "Le", "Pham"],
          language: "zh"
        },
        {
          firsts: ["Aarav", "Vivaan", "Diya", "Aarush", "Aarav", "Vivaan"],
          lasts: ["Patel", "Kumar", "Sharma", "Sharma", "Patel", "Kumar"],
          language: "hi"
        },
        {
          firsts: ["Zane", "Amara", "Kwame", "Kofi", "Ama", "Kwaku"],
          lasts: ["Okonkwo", "Mwangi", "Abioye", "Osei", "Osei", "Osei"],
          language: "sw"
        },
        {
          firsts: ["Layla", "Omar", "Fatima", "Ali", "Aisha", "Mohammed"],
          lasts: ["Almasi", "Hakimi", "Sabbagh", "Ahmed", "Fatoush", "Abdullah"],
          language: "ar"
        },
      ];

      const name = names[this.getRandomIndex(names.length)];

      return {
        first_name: name.firsts[this.getRandomIndex(name.firsts.length)],
        last_name: name.lasts[this.getRandomIndex(name.lasts.length)],
        language: name.language
      }
    },

    getRandomIndex(arrayLength) {
       return Math.floor(Math.random() * arrayLength);
    },

    setDefaultField(key, value) {
      this.form.enroll_enabled_fields[key].default = value;
      this.form.enroll_enabled_fields[key].hidden = true;
    },

    renderField(key) {

      if(this.form['settings'] && this.form.settings[key] && this.form.settings[key][this.current_language]) {
        return this.$DOMPurify.sanitize(this.form.settings[key][this.current_language]);
      }

      if(this.form[key] && this.form[key][this.current_language] && this.form[key][this.current_language].length) {
        return this.$DOMPurify.sanitize(this.form[key][this.current_language]);
      }

      if(this.form[key] && this.form[key]['en'] && this.form[key]['en'].length) {
        return this.$DOMPurify.sanitize(this.form[key]['en']);
      }
    },

    editEnrollField(index, event) {

      var fields = this.form.enroll_enabled_fields;

      fields[index].name = event.target.innerHTML || '';

      fields[index].name = fields[index].name.replace(/<br>/g, '');

      this.form.enroll_enabled_fields = fields;

      this.changed = true;
      this.postForm();
    },

    editEnrollSetting(key, event) {
      this.form[key] = event.target.innerHTML;
      this.changed = true;
      this.postForm();
    },

    setEditorValue(field) {

      if(!this.form || !this.form.settings) {
        return;
      }

      if(field == 'welcome' && this.form.welcome) {
        return this.form.welcome[this.current_language] || this.form.welcome['en'];
      }

      if(this.form.settings[field] && this.form.settings[field][this.current_language]) {
        return this.form.settings[field][this.current_language];
      }
    },

    resetEditors() {

      if(this.current_menu == 'dashboard' && this.$refs['dashboard']) {
        this.$refs['dashboard'].invoke('show');
        this.$refs['dashboard'].invoke('setHTML', this.setEditorValue('dashboard'));
        return;
      }

      if(this.current_menu == 'library' && this.$refs['library']) {
        this.$refs['library'].invoke('show');
        this.$refs['library'].invoke('setHTML', this.setEditorValue('library'));
        return;
      }

      if(this.current_menu == 'welcome' && this.$refs['welcome']) {
        this.$refs['welcome'].invoke('show');
        this.$refs['welcome'].invoke('setHTML', this.setEditorValue('welcome'));
        return;
      }

      if(this.$refs['dashboard']) {
        this.$refs['dashboard'].invoke('hide');
      }

      if(this.$refs['library']) {
        this.$refs['library'].invoke('hide');
      }
    },

    changeLanguage(language) {
      this.current_language = language;
      this.resetEditors();
    },

    changeMenu(menu) {

      this.current_menu = menu;

      this.resetEditors();

      setTimeout(() => {
        this.resetEditors();
      }, 1);
    },

    changeMultiEditor(field) {
      this.form[field][this.current_language] = this.$refs[field].invoke('getHTML');
      this.changed = true;
    },

    changeEditor(field) {
      this.form.settings[field][this.current_language] = this.$refs[field].invoke('getHTML');
      this.changed = true;
    },

    editCheckinField(index, event) {

      var fields = this.form.settings['checkin_fields'];

      fields[index].name = event.target.innerHTML;

      fields[index].name = fields[index].name.replace(/<br>/g, '');

      this.form.settings['checkin_fields'] = fields;

      this.changed = true;
    },

    editField(key, event) {

      if(!['optouts.reasons', 'optouts.title', 'dashboard'].includes(key)) {
        this.form[key][this.current_language] = event.target.innerHTML;
      }

      if(key == 'optouts.reasons') {
        this.form.optouts['reasons'][this.current_language] = event.target.innerHTML;
      }

      if(key == 'optouts.title') {
        this.form.optouts['title'][this.current_language] = event.target.innerHTML;
      }

      if(key == 'dashboard') {
        if(!this.form.settings['dashboard']) {
          this.form.settings['dashboard'] = {};
        }
        this.form.settings['dashboard'][this.current_language] = event.target.innerHTML;
      }

      this.changed = true;
    },

    toggleMenu(menu) {
      this.current_menu = menu;
    },

    toggleLanguage(language) {
      if(this.current_language == language.key) {
        this.current_language = 'en';
      } else {
        this.current_language = language.key;
      }
    },

    setLanguageLabel(key) {
      var language = _.find(this.languages, {key: key});

      if(!language || !language.label) {
        return;
      }
      
      return language.label;
    },

    getCaseStatuses() {
      this.setCaseTypes();
      this.$http.get('/statuses?type=case&fields=name,uuid&limit=100&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          
          this.processing = false;

          this.case_statuses = [];

          if(!response.data.length) {
            return;
          }

          this.case_statuses = response.data;
          this.case_statuses = this.case_statuses.map(status => {
            return {
              key: status.uuid,
              name: status.name
            }
          });
        }
      })
    },

    setCaseTypes() {
      if(this.settings && this.settings.case_types) {
        this.case_types = this.settings.case_types.split(',');
      }
    }

  },
}
</script>