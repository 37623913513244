<template>
    <div>
  
      <archived-trashed-status
          :form="form"
          :processing="processing"
          :title="$route.meta.title"
      />
  
      <div class="row mb-3">
        <div class="col-sm-12 col-md-4">
          <p>
            <router-link :to="{name: 'settings.index'}">Settings</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
          </p>
  
        </div><!--Col-->

        <div class="col-sm-12 col-md-4">
          <template v-if="form.uuid">
            <button class="btn btn-outline-secondary btn-sm" @click="copyToClipboard('https://webhooks.ecourtdate.net/chatbots/'+form.uuid)" :disabled="processing">Copy Webhook URL</button>
          </template>
        </div>
  
        <div class="col-sm-12 col-md-4 text-right">
          <div class="btn-group">
  
            <div class="btn-group">
              <button
                  type="button"
                  class="btn btn-outline-primary dropdown-toggle radius-right-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                    v-if="!form.archived_at"
                    class="dropdown-item"
                    @click="archiveRecord()"
                >Archive</a>
                <a
                    v-if="form.archived_at || form.deleted_at"
                    class="dropdown-item"
                    @click="restoreRecord()"
                >Restore</a>
                <a
                    v-if="!form.deleted_at"
                    class="dropdown-item"
                    @click="trashRecord()"
                >Trash</a>
              </div>
            </div><!--BtnGroup-->
  
            <button
                v-if="!form.archived_at && !form.deleted_at"
                type="button"
                class="btn btn-success"
                :disabled="processing"
                @click="postForm()"
            >
              Save
            </button>
  
            <router-link
                :to="{ name: $route.meta.base_url+'.index' }"
                class="btn btn-danger"
            >
              Close
            </router-link>
  
          </div>
        </div><!--Col-->
      </div><!--Row-->
  
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title">
                    Edit {{ $route.meta.title }}
                  </p>
                </div><!--Col-->
  
                <div class="col-6 text-right">
                </div><!--Col-->
              </div><!--Row-->
  
              <form @submit.prevent="postForm">
  
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                      v-if="form"
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      required
                  >
                </div>
                <div class="form-group">
                  <label for="model">AI Model</label>
                  <select
                      id="model"
                      v-model="form.model"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="model in ai_models">
                      <option :value="model">{{ model }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                    <label for="subject">Default Subject</label>
                    <input
                        id="subject"
                        v-model="form.settings.subject"
                        type="text"
                        class="form-control"
                    >
                    <p class="form-text text-muted">
                        This will be used as the subject of the response when the chatbot replies to an inbound message.
                    </p>
                </div>

                <template v-if="form.model != 'custom'">

                    <div class="form-group">
                        <label for="api_key">API Key</label>
                        <input
                            id="api_key"
                            v-model="form.settings.api_key"
                            type="password"
                            class="form-control"
                        >
                        <p class="form-text text-muted">
                            Enter the API key for the AI model you've selected. Otherwise, our default API key for testing will be used.
                        </p>
                    </div>

                </template>

                <template v-if="form.model == 'custom'">
                    <div class="form-group">
                        <label for="api_url">Custom API URL</label>
                        <input
                            id="api_url"
                            v-model="form.settings.api_url"
                            type="text"
                            class="form-control"
                        >
                    </div>

                    <div class="form-group">
                        <label for="custom_headers">Custom Headers</label>
                        <textarea
                            id="custom_headers"
                            v-model="form.settings.custom_headers"
                            class="form-control"
                        ></textarea>
                    </div>
                </template>

                <div class="form-group">
                    <label for="max_tokens">Max Tokens</label>
                    <input
                        id="max_tokens"
                        v-model="form.settings.max_tokens"
                        type="number"
                        class="form-control"
                    >
                </div>

                <div class="form-group">
                    <label for="temperature">Temperature</label>
                    <input
                        id="temperature"
                        v-model="form.settings.temperature"
                        type="number"
                        class="form-control"
                    >
                </div>

                <div class="form-group">
                    <label for="response_format">Response Format</label>
                    <select
                        id="response_format"
                        v-model="form.settings.response_format"
                        class="form-control"
                        :disabled="processing"
                    >
                        <option value=""></option>
                        <template v-for="format in formats">
                            <option :value="format">{{ format }}</option>
                        </template>
                    </select>
                </div>


                <div class="form-group">
                  <label for="status">Status</label>
                  <select
                      id="status"
                      v-model="form.status"
                      class="form-control text-uppercase"
                      :disabled="processing"
                  >
                  </select>
                </div>
  
                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
          <audit-logs
              class="mt-3"
              :form="form"
          />
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Generate a response
              </p>

              <form @submit.prevent="generateResponse">

                <div class="form-group">
                  <label for="content">Message</label>
                  <textarea
                      id="content"
                      v-model="genForm.content"
                      class="form-control"
                  ></textarea>
                </div>

                <button type="submit" class="btn btn-success" :disabled="processing">Generate</button>

              </form>

              <template v-if="genResponse">

                <template v-if="genResponse.message && genResponse.message.content && genResponse.message.content[0]">
                  <p class="mt-3 outbound">
                    {{ genResponse.message.content[0].text }}
                  </p>
                </template>

              </template>


            </div><!--Body-->
          </div><!--Card-->
  
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-3">

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="card-title">
                  {{ messages.length }} of {{ total_messages }} Messages
                </p>
            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">
              <button class="btn btn-outline-secondary btn-sm" @click="getMessages()" :disabled="processing"><i class="fa fa-refresh"></i></button>
            </div><!--Col-->

          </div><!--Row-->


                <template v-for="message in messages">
                  <message :message="message" />
                </template>

  
        </div><!--Col-->
  
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        records: [],
        form: {},
        genForm: {},
        formats: ['text', 'json', 'markdown'],
        ai_models: [
            'auto',
            'custom',
            'gpt-3.5-turbo',
            'gpt-3.5-turbo-davinci',
            'gpt-3.5-turbo-codex',
            'gpt-4.0-turbo',
            'gpt-4.0-turbo-davinci',
            'gpt-4o',
            'claude-3-5-sonnet-20241022',
            'claude-3-5-sonnet-latest',
            'claude-3-opus-20240229',
            'claude-3-opus-latest'
        ],
        messages: [],
        total_messages: 0,
        genResponse: null
      }
    },

  
    created() {
      this.resetForm();
      this.setURLParams();
      this.getRecord();
    },
  
    methods: {
  
      setURLParams() {
  
        var searchParams = new URLSearchParams(window.location.search);
  
        var self = this;
  
        searchParams.forEach(function(value, key) {
          self.queries[key] = value;
        });
      },
  
      postForm() {
        this.processing = true;
  
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
              if(response && response.data) {
  
                this.processing = false;
  
                if(response.data.updated_at) {
                  this.form.updated_at = response.data.updated_at;
                  new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                }
  
              }
            })
      },
  
      resetForm() {
        this.form = {
          name: null,
          model: null,
          status: null,
          settings: {}
        }
      },
  
      getRecord() {
        this.processing = true;
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
            .then(response => {
              if(response && response.data) {
  
                if(response.data.uuid) {
                  this.form = response.data;

                  if(!this.form.settings) {
                    this.form.settings = {};
                  }

                  this.processing = false;

                  this.getMessages();
                }
  
              }
            })
      },

      getMessages() {

        var queries = {
          type: 'chatbot:'+this.form.uuid
        }

        this.getTotalMessages(queries);

        this.processing = true;

        this.$http.get('/messages', {params: queries})
            .then(response => {
              if(response && response.data && response.data.data) {
                this.messages = response.data.data;
                this.processing = false;
              }
            })
      },

      getTotalMessages(queries) {
        this.$http.get('/messages/total', {params: queries})
            .then(response => {
              if(response && response.status === 200) {
                this.total_messages = response.data;
              }
            })
      },

      generateResponse() {
        this.processing = true;

        this.genResponse = null;

        this.$http.post('/chatbots/'+this.form.uuid+'/generator', this.genForm)
            .then(response => {
              if(response && response.data) {
                this.processing = false;
                this.genResponse = response.data;
              }
            })

      },
  
      restoreRecord () {
        this.processing = true;
        this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
            .then(response => {
              if (response && response.data) {
  
                this.processing = false;
  
                if(response.status === 200) {
                  new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                  this.form.deleted_at = null;
                  this.form.archived_at = null;
                }
  
              }
            })
      },
  
      archiveRecord () {
        this.processing = true
        this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
            .then(response => {
              if (response && response.data) {
  
                this.processing = false;
  
                if(response.status === 200) {
                  new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                  this.form.archived_at = new Date().toISOString();
                }
  
              }
  
            })
      },
  
      trashRecord () {
        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
            .then((response) => {
              if(response && response.status) {
                this.processing = false;
  
                if(response.status === 204) {
                  new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                  this.form.deleted_at = new Date().toISOString();
                }
              }
            })
      }
  
    },
  }
  </script>